<template>
  <div class="beauty" style="position: relative">
    <img src="../../assets/product/beauty/1.png" class="headerimg" />
    <div class="scope">
      <div class="title">应用范围</div>
      <div class="scopelist">
        <div class="scopecard">
          <div class="scopeimg">
            <img src="../../assets/product/beauty/2.png" alt="" />
          </div>
          <div class="scopetitle">美容美体</div>
        </div>
        <div class="scopecard">
          <div class="scopeimg">
            <img src="../../assets/product/beauty/3.png" alt="" />
          </div>
          <div class="scopetitle">美甲美睫</div>
        </div>
        <div class="scopecard">
          <div class="scopeimg">
            <img src="../../assets/product/beauty/4.png" alt="" />
          </div>
          <div class="scopetitle">美发养发</div>
        </div>
        <div class="scopecard">
          <div class="scopeimg">
            <img src="../../assets/product/beauty/5.png" alt="" />
          </div>
          <div class="scopetitle">spa养生</div>
        </div>
        <div class="scopecard">
          <div class="scopeimg">
            <img src="../../assets/product/beauty/6.png" alt="" />
          </div>
          <div class="scopetitle">多态美业</div>
        </div>
        <div class="scopecard">
          <div class="scopeimg">
            <img src="../../assets/product/beauty/7.png" alt="" />
          </div>
          <div class="scopetitle">更多</div>
        </div>
      </div>
    </div>

    <div class="industry">
      <div class="title">行业现状</div>
      <div class="industrylsit">
        <div class="industrycard">
          <div class="industryimg">
            <img src="../../assets/product/beauty/8.png" alt="" />
          </div>
          <div class="industryinfo">
            <div class="industrytitle">客源少，拓客难</div>
            <div class="industrytext">
              客源少，客源增长缓慢，做来做去总是那么几个老顾客
            </div>
          </div>
        </div>
        <div class="industrycard">
          <div class="industryimg">
            <img src="../../assets/product/beauty/9.png" alt="" />
          </div>
          <div class="industryinfo">
            <div class="industrytitle">会员维护不到位</div>
            <div class="industrytext">
              对会员没有做精细化管理，会员关怀不足，延续性也不够
            </div>
          </div>
        </div>
        <div class="industrycard">
          <div class="industryimg">
            <img src="../../assets/product/beauty/10.png" alt="" />
          </div>
          <div class="industryinfo">
            <div class="industrytitle">资金回笼困难</div>
            <div class="industrytext">
              美容养生的产品、仪器等都是需要大量资金进行前期投入的，主导资金的投资力度不够
            </div>
          </div>
        </div>
        <div class="industrycard">
          <div class="industryimg">
            <img src="../../assets/product/beauty/11.png" alt="" />
          </div>
          <div class="industryinfo">
            <div class="industrytitle">数据账目不清晰</div>
            <div class="industrytext">
              顾客消费统计都靠人手记录，容易导致账目收支不清晰
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">解决方案</div>

      <div class="caselist listtop">
        <div class="casecard">
          <div class="casetitle">
            <span class="num">01</span
            ><span class="punctuation">.</span> 轻松拓客：线上线下全方位引进客流
          </div>
          <div class="caseinfo">
            <div class="round"></div>
            多渠道推广
          </div>
          <div class="casetext">
            结合线下员工推广、传单海报等推广场景，通过奖励形式来刺激线下推广渠道的积极性并监控推广成效
          </div>

          <div class="caseinfo">
            <div class="round"></div>
            搭建自有小程序
          </div>
          <div class="casetext">
            依托小程序获得微信自有流量，还有“附近小程序”这个功能，有效拓展门店附近的客源
          </div>

          <div class="caseinfo">
            <div class="round"></div>
            大转盘、进店有礼等拓客营销活动
          </div>
          <div class="casetext">
            游戏+抽奖的模式，让活动更具吸引力，注册会员即可领取,进店有礼优惠券，让你用更低成本获取流量
          </div>
        </div>

        <div class="caseimg">
          <img src="../../assets/product/beauty/12.png" alt="" />
        </div>
      </div>

      <div class="caselist">
        <div class="caseimg">
          <img src="../../assets/product/beauty/13.png" alt="" />
        </div>

        <div class="casecard">
          <div class="casetitle">
            <span class="num">02</span
            ><span class="punctuation">.</span> 会员维护：会员精细化管理
          </div>
          <div class="caseinfo">
            <div class="round"></div>
            会员档案
          </div>
          <div class="casetext">记录会员的全方位信息</div>

          <div class="caseinfo">
            <div class="round"></div>
            会员关怀
          </div>
          <div class="casetext">
            在会员生日或节日时发送关怀短信和优惠券，大大提升了品牌好感度，也可有效唤醒沉睡会员
          </div>

          <div class="caseinfo">
            <div class="round"></div>
            智能预约
          </div>
          <div class="casetext">
            顾客在小程序自助预约空闲的美容师，避免了到店后等待时间过长的问题，提升了顾客体验
          </div>
        </div>
      </div>

      <div class="caselist">
        <div class="casecard">
          <div class="casetitle">
            <span class="num">03</span
            ><span class="punctuation">.</span> 资金沉淀：储值、开卡回笼资金
          </div>
          <div class="caseinfo">
            <div class="round"></div>
            会员储值赠送
          </div>
          <div class="casetext">
            通过引导会员储值，给出相应的优惠或者是赠送，这样会员进行储值商家不仅拥有现金流，还可以刺激会员多次复购消费
          </div>

          <div class="caseinfo">
            <div class="round"></div>
            开设优惠次卡、年卡
          </div>
          <div class="casetext">
            组合多个服务项目搭配包装成为次卡、年卡优惠套餐，吸引顾客购买，办卡后客户直接出示小程序会员二维码即可消耗卡次
          </div>
        </div>

        <div class="caseimg">
          <img src="../../assets/product/beauty/14.png" alt="" />
        </div>
      </div>

      <div class="caselist">
        <div class="caseimg">
          <img src="../../assets/product/beauty/15.png" alt="" />
        </div>

        <div class="casecard">
          <div class="casetitle">
            <span class="num">04</span
            ><span class="punctuation">.</span> 经营数据：提升数据管理能力
          </div>
          <div class="caseinfo">
            <div class="round"></div>
            订单数据
          </div>
          <div class="casetext">
            邦伲德门店通有服务项目、卡项和会员充值购买三种订单数据，区分明确，数据明晰
          </div>

          <div class="caseinfo">
            <div class="round"></div>
            财务数据
          </div>
          <div class="casetext">
            准确核算订单收款和余额卡项等消耗统计，区分线上收款和记账收款等不同账单实收统计数据
          </div>
        </div>
      </div>
    </div>

    <div class="scene">
      <div class="title">运用场景</div>

      <div class="scenelist">
        <div class="scenecard">
          <div class="scenetitle">门店引流</div>
          <div class="sceneinfo">
            <div class="infoone">
              <div class="infotitle">线上</div>
              <div class="inflline">进店有礼</div>
              <div class="inflline">推广员</div>
              <div class="inflline">小程序</div>
              <div class="inflline">营销海报</div>
              <div class="inflline">拼团</div>
              <div class="inflline">大转盘</div>
            </div>

            <div class="infoone">
              <div class="infotitle">线下</div>
              <div class="inflline">渠道推广码</div>
              <div class="inflline">异业合作</div>
              <div class="inflline">员工推广奖励</div>
              <div class="inflline">卡券系统</div>
            </div>
          </div>
        </div>

        <div class="scenecard">
          <div class="scenetitle">客人进店</div>
          <div class="sceneinfo">
            <div class="infoone" style="width: 100%">
              <div class="infotitle">在线预约</div>
              <div class="inflline">商家端预约</div>
              <div class="inflline">小程序预约</div>
              <div class="inflline">预约提醒</div>
              <div class="inflline">预约记录</div>
              <div class="inflline">会员预约码</div>
            </div>
          </div>
        </div>

        <div class="scenecard">
          <div class="scenetitle">开始服务</div>
          <div class="sceneinfo">
            <div class="infoone">
              <div class="infotitle">到店转化</div>
              <div class="inflline">会员储值</div>
              <div class="inflline">购买次卡</div>
              <div class="inflline">购买年卡</div>
            </div>

            <div class="infoone">
              <div class="infotitle">员工端</div>
              <div class="inflline">员工开单</div>
              <div class="inflline">员工业绩</div>
            </div>
          </div>
        </div>

        <div class="scenecard">
          <div class="scenetitle">前台收银</div>
          <div class="sceneinfo">
            <div class="infoone">
              <div class="infotitle">收银方式</div>
              <div class="inflline">手机端</div>
              <div class="inflline">电脑端</div>
            </div>

            <div class="infoone">
              <div class="infotitle">便捷收款</div>
              <div class="inflline">多种支付方式</div>
              <div class="inflline">会员卡</div>
              <div class="inflline">优惠券</div>
              <div class="inflline">优惠权益</div>
              <div class="inflline">会员余额</div>
            </div>
          </div>
        </div>

        <div class="scenecard">
          <div class="scenetitle">客人离店</div>
          <div class="sceneinfo">
            <div class="infoone">
              <div class="infotitle">离店维护</div>
              <div class="inflline">数据档案</div>
              <div class="inflline">活动推送</div>
              <div class="inflline">短信营销</div>
              <div class="inflline">客户关怀</div>
              <div class="inflline">线上店铺</div>
            </div>

            <div class="infoone">
              <div class="infotitle">会员裂变</div>
              <div class="inflline">口碑传播</div>
              <div class="inflline">评价体系</div>
              <div class="inflline">转介绍有礼</div>
              <div class="inflline">裂变营销</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scheme">
      <div class="title">案例</div>
      <div class="schemelist">
        <div class="schemecard schemeback1">
          <div class="schemetitle">精致国际美容</div>
        </div>
        <div class="schemecard schemeback2">
          <div class="schemetitle">欣荣中医健康管理</div>
        </div>
        <div class="schemecard schemeback3">
          <div class="schemetitle">比莉美发乐园</div>
        </div>
        <div class="schemecard schemeback4">
          <div class="schemetitle">美涵美容</div>
        </div>
      </div>
    </div>

    <div class="precept">
      <div class="preceptlist">
        <div class="preceptimg">
          <img src="../../assets/product/beauty/20.png" alt="" />
        </div>
        <div class="preceptcard">
          <div class="preceptinfo">
            <div class="precepttitle">背景</div>
            <div class="precepttext">
              欣荣中医健康管理位于刚开业的商场一楼的入口处，这位置是相当好，进入店内体验都是自然流量，但是客户
              一次性消费之后，就再没踏入店内消费。
            </div>

            <div class="precepttitle">解决问题</div>
            <div class="precepttext">
              小程序+社交拓客（拼团、老带新、推广员）工具，帮助精致国际美容维护老客、开拓新客；可设置员工权限， 满足员工多重角色；会员提醒、预约提醒等功能帮助员工紧密与会员的关系。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.precept {
  .preceptlist {
    margin: auto;
    display: flex;
    margin-bottom: 140px;
    width: 73%;
    justify-content: space-between;
    height: 352px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
    .preceptimg {
      img {
        width: 453px;
      }
    }

    .preceptcard {
      .preceptinfo {
        margin-left: 81px;
        margin-right: 40px;
        .precepttitle {
          font-size: 24px;
          font-weight: 500;
          line-height: 33px;
          margin-top: 40px;
        }
        .precepttext {
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
          margin-top: 20px;
        }
      }
    }
  }
}

.scheme {
  .schemelist {
    margin: 60px auto;
    display: flex;
    width: 73%;
    justify-content: space-between;
    .schemeback1 {
      background-image: url("../../assets/product/beauty/16.png");
    }
    .schemeback2 {
      background-image: url("../../assets/product/beauty/17.png");
    }
    .schemeback3 {
      background-image: url("../../assets/product/beauty/18.png");
    }
    .schemeback4 {
      background-image: url("../../assets/product/beauty/19.png");
    }
    .schemecard {
      width: 325px;
      height: 152px;
      background-size: 100% 100%;
      .schemetitle {
        line-height: 152px;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}

.round {
  width: 16px;
  height: 16px;
  background: linear-gradient(180deg, #5cb6fe 0%, #2f7ffc 100%);
  border-radius: 50%;
  margin-right: 20px;
  display: inline-block;
}

.scene {
  .scenelist {
    margin: auto;
    display: flex;
    width: 73%;
    margin-top: 60px;
    justify-content: space-between;
    margin-bottom: 140px;
    .scenecard {
      width: 259px;
      height: 352px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      .scenetitle {
        width: 259px;
        height: 82px;
        background: linear-gradient(90deg, #80d8ff 0%, #ea80fc 100%);
        border-radius: 14px 14px 0px 0px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: #ffffff;
        line-height: 82px;
      }
      .sceneinfo {
        margin: 40px 30px;
        display: flex;
        justify-content: space-between;
        .infoone {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
          vertical-align: top;
          .infotitle {
            margin-bottom: 20px;
          }
          .inflline {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.case {
  .listtop {
    margin-top: 60px !important;
  }
  .caselist {
    margin: auto;
    display: flex;
    width: 73%;
    justify-content: space-between;
    margin-bottom: 140px;
    align-items: center;
    .caseimg {
      img {
        width: 560px;
      }
    }

    .casecard {
      .casetitle {
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        line-height: 70px;
        margin-bottom: 33px;
        .num {
          color: #2f7ffc;
          font-size: 50px;
        }
        .punctuation {
          color: #2f7ffc;
        }
      }

      .caseinfo {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        line-height: 33px;
        margin-bottom: 10px;
      }

      .casetext {
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 400;
        color: #616b80;
        line-height: 33px;
        width: 684px;
      }
    }
  }
}

.industry {
  margin: 140px 0;
  background: #f5f7fa;
  padding: 90px 0px;
  .industrylsit {
    margin: auto;
    display: flex;
    width: 73%;
    justify-content: space-between;
    margin-top: 60px;
    .industrycard {
      width: 325px;
      height: 404px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      .industryimg {
        img {
          width: 325px;
        }
      }
      .industryinfo {
        margin: 30px 26px;
        margin-top: 30px;
        .industrytitle {
          font-size: 24px;
          font-weight: 500;
          color: #616b80;
          line-height: 33px;
        }
        .industrytext {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
        }
      }
    }
  }
}

.scope {
  z-index: 111;
  height: 484px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.18);
  width: 1400px;
  background-color: #fff;
  margin: auto;
  margin-top: -132px;
  border-radius: 20px;
  padding: 90px 96px;
  box-sizing: border-box;
  position: relative;
  left: 0;
  top: 0;
  .scopelist {
    margin-top: 58px;
    display: flex;
    justify-content: space-between;
    .scopecard {
      text-align: center;
      .scopeimg {
        img {
          width: 148px;
        }
      }
      .scopetitle {
        margin-top: 24px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }
    }
  }
}
</style>
